<template>
  <div class="share-page page">
    <BreadcrumbsComponent @back.prevent :links="links" />
    <div class="share-page__title-container">
      <h1 class="share-page__title">{{ share.title }}</h1>
    </div>
    <div class="share-page__container">
      <div class="share-page__content">
        <div class="share-page__img">
          <ImgComponent :head_img="share.head_img" quality="99" />
        </div>
        <div v-if="share.description" class="share-page__description">
          <EditorJSComponent :text="share.description" />
        </div>
        <div v-if="products" class="share-page__products" ref="list">
          <ProductCardComponent v-for="(item, i) in products.data" :key="i" :data="item" />
        </div>
        <span v-else>Товары отсутствуют</span>
        <PaginationComponent
          v-if="showPagination"
          :page="page"
          :total="Math.ceil(products.paginatorInfo.total / first)"
          @change="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SHARE_PAGE from "@/graphql/pages/SharePage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import PaginationComponent from "components/Pagination.vue";

export default {
  name: "SharePage",
  data() {
    return {
      page: 1,
      paginateLoading: false,
    };
  },
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: SHARE_PAGE,
        variables: {
          id: parseInt(route.params.id),
          first: store.state.isMobile ? 12 : 16,
          page: 1,
        },
      })
      .then(({ data }) => {
        store.state.share_page.share = data.share;
        store.state.share_page.products = data.products_paginate;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  computed: {
    links() {
      return [{ title: "Акции", route: { name: "shares" } }, { title: this.share.title }];
    },
    share() {
      return this.$store.state.share_page.share;
    },
    products() {
      return this.$store.state.share_page.products;
    },
    showShares() {
      return this.products && this.products.data && this.products.data.length;
    },
    showPagination() {
      return this.showShares && Math.ceil(this.products.paginatorInfo.total / this.first) > 1;
    },
    first() {
      return this.$store.state.isMobile ? 12 : 16;
    },
  },
  methods: {
    load() {
      this.$apollo.provider.defaultClient
        .query({
          query: SHARE_PAGE,
          variables: {
            id: parseInt(this.$route.params.id),
            first: this.first,
            page: this.page,
          },
        })
        .then(({ data }) => {
          this.$store.state.share_page.share = data.share;
          this.$store.state.share_page.products = data.products_paginate;
        })
        .finally(() => {
          this.paginateLoading = false;
        });
    },
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    scrollUp() {
      if (window.scrollY > this.$refs.list.offsetTop) {
        window.scrollTo({ top: this.$refs.list.offsetTop });
      }
    },
  },
  components: {
    PaginationComponent,
    ProductCardComponent,
    BreadcrumbsComponent,
    EditorJSComponent,
  },
  metaInfo() {
    return {
      title: this.share.meta_title,
      description: this.share.meta_description,
      keywords: this.share.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.share-page {
  width 100%
  display grid
  grid-gap 32px
  padding-top 32px
  padding-bottom 48px
  +below(420px) {
    grid-gap 16px
  }

  &__title {
    max-width var(--main_width)
    width 100%
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0 auto

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 0 15px;
  }

  &__content {
    display grid
    grid-gap 32px
    width 100%
    max-width var(--main_width)
  }

  &__img {
    width 100%
    height 290px

    img {
      width 100%
      height 100%
      object-fit cover
      object-position center
      border-radius var(--main_radius)
    }
  }

  &__description {
    display flex
    width 100%
    max-width var(--small_width)
    margin 0 auto
  }

	&__products {
		display grid
		grid-template-columns repeat(4, 1fr)
		grid-gap 24px
		+below(990px) {
			grid-template-columns repeat(3, 1fr)
			grid-gap 16px
		}
		+below(720px) {
			grid-template-columns repeat(2, 1fr)
			grid-gap 8px
		}
		+below(380px) {
			grid-template-columns 1fr
		}
	}
}
</style>
